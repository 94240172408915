import React, { useEffect, useState } from 'react';
import { toast, Toaster } from 'sonner';
import { patchData } from '../utility/fetcher';
import { BackArrow } from '../utility/BackArrow';

export const SSO = () => {
    const [SSOUrl, setSSOUrl] = useState("");
    const [casServerURL, setcasServerURL] = useState("");
    const [SSOMetadataXML, setSSOMetadataXML] = useState("");
    const [errors, setErrors] = useState({ SSOUrl: "", casServerURL: "" });
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const [loading, setLoading] = useState(SSOUrl ? false : true);
    const orgID = orgData.orgID;

    const getData = async () => {
        setSSOUrl(orgData.SSOUrl);
        setcasServerURL(orgData.casServerURL);
        setSSOMetadataXML(orgData.SSOMetadataXML);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const validateURL = () => {
        if (casServerURL.length < 16) {
            return "Must be a valid URL";
        };
        return "";
    };

    const validateInput = () => {
        let newErrors = {
            SSOUrl: validateURL(),
            casServerURL: validateURL()
        };

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };

    const handleSave = async () => {
        if (!validateInput()) {
            toast.error("Please fix the errors before saving!");
            return
        };

        toast.info("Saving SSO settings...");
        const response = await patchData(`/general/organizations?orgID=${orgID}`, {
            orgID,
            SSOUrl,
            casServerURL,
            SSOMetadataXML
        });

        const newOrgData = {
            ...orgData,
            SSOUrl,
            casServerURL,
            SSOMetadataXML
        };

        if (response.error) {
            toast.error("Failed to save SSO settings!");
            return;
        } else {
            localStorage.setItem("orgData", JSON.stringify(newOrgData));
            toast.success("SSO settings saved successfully!");
        };
    };

    return (
        <div className="flex flex-col m-4 ml-20 font-rubik justify-center items-center">
            <BackArrow path={"/settings/users"} />
            <h1 className="font-bold text-3xl m-4">SSO Settings</h1>

            {loading ? <p className='mt-20'>Loading...</p> : (
                <div className="border p-4 rounded-xl mt-10">
                    <label className="block mb-2 text-lg">SAML URL</label>
                    <input
                        type="text"
                        value={SSOUrl}
                        onChange={(e) => setSSOUrl(e.target.value)}
                        onBlur={() => setErrors({ ...errors, SSOUrl: validateURL() })}
                        className="w-full p-2 mb-2 border rounded-lg outline-spotYellow"
                        placeholder="Enter SAML URL"
                    />
                    {errors.SSOUrl && <p className="text-red-500 text-sm">{errors.SSOUrl}</p>}

                    <label className="block mb-2 text-lg">CAS URL</label>
                    <input
                        type="text"
                        value={casServerURL}
                        onChange={(e) => setcasServerURL(e.target.value)}
                        onBlur={() => setErrors({ ...errors, casServerURL: validateURL() })}
                        className="w-full p-2 mb-2 border rounded-lg outline-spotYellow"
                        placeholder="Enter Valid URL"
                    />
                    {errors.casServerURL && <p className="text-red-500 text-sm">{errors.casServerURL}</p>}

                    <label className="block mb-2 text-lg">SSO Metadata</label>
                    <input
                        type="text"
                        value={SSOMetadataXML}
                        onChange={(e) => setSSOMetadataXML(e.target.value)}
                        className="w-full p-2 mb-2 border rounded-lg outline-spotYellow"
                        placeholder="Paste XML here"
                    />

                    <button
                        onClick={handleSave}
                        className="w-full mt-2 bg-spotYellow hover:shadow-lg text-spotGray transition font-semibold py-2 rounded-lg"
                    >
                        Save Changes
                    </button>
                </div>
            )}
            <Toaster richColors />
        </div>
    );
}