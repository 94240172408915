import React, { useState, useRef, useEffect } from "react";
import { fetchAllData } from "../utility/fetcher";
import { toast } from "sonner";

export const SpotEditor = ({ closeEditor, camera }) => {
    const cameraData = JSON.parse(sessionStorage.getItem("currentCamera")) || camera;
    const [currentSpotData, setCurrentSpotData] = useState([]);
    const [selectedSpotIndices, setSelectedSpotIndices] = useState(new Set());
    const [hoveredSpotIndex, setHoveredSpotIndex] = useState(null);
    const [resolution, setResolution] = useState({ width: 0, height: 0 });
    const [changedTypes, setChangedTypes] = useState([]);
    // eslint-disable-next-line
    const [errorOut, setErrorOut] = useState(false);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [spotData, setSpotData] = useState([]);
    const editorRef = useRef(null);
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const permits = orgData.permitTypes;
    const lot = camera.lotNum
    const orgID = orgData.orgID;
    const [loaded, setloaded] = useState(false);

    const storeData = async () => {
        const cameraName = cameraData.name.replace(/\s/g, "+");
        const paths = {
            "image": `/public/getCameraFrame?orgID=${orgID}&lot=${lot}&cameraName=${cameraName}`,
            "spotData": `/${orgID}/spots`
        };

        const response = await fetchAllData(paths);
        const imageData = response.image.CDN_URL;
        const spotData = response.spotData;
        setSpotData(spotData);

        if (!imageData) {
            console.log("IMAGE ERROR", imageData)
            closeEditor("error");
            toast.error("Error loading camera frame");
            return
        }

        // if (data.spotData.error) {
        //     console.error(data.spotData.error);
        //     setErrorOut(true);
        // }
        // setCurrentSpotData(data.spotData);
        setImage(imageData);
        setLoading(false);
    }

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const polygonBuild = []

        for (let i in spotData) {
            const activeSpot = spotData[i]
            let currentSpotBuild = {}
            currentSpotBuild.spotID = activeSpot.spotID
            currentSpotBuild.type = activeSpot.permitType

            if (activeSpot.cameras) {
                if (activeSpot.cameras[cameraData.name]) {
                    currentSpotBuild.boundingBox = activeSpot.cameras[cameraData.name]
                    polygonBuild.push(currentSpotBuild)
                }
            }
        }

        setCurrentSpotData(polygonBuild)
        // eslint-disable-next-line
    }, [spotData])

    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setResolution({ width: naturalWidth, height: naturalHeight });
    };

    const handleClickOutside = (event) => {
        if (editorRef.current && !editorRef.current.contains(event.target)) {
            setloaded(false)
            saveToDatabase();
        }
    }

    const handleSpotClick = (index, e) => {
        setSelectedSpotIndices(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (e.ctrlKey || e.metaKey) {
                if (newSelected.has(index)) {
                    newSelected.delete(index);
                } else {
                    newSelected.add(index);
                }
            } else {
                newSelected.clear();
                newSelected.add(index);
            }

            return newSelected;
        });
    };

    const handleSpotMouseEnter = (index) => {
        setHoveredSpotIndex(index);
    };

    const handleSpotMouseLeave = () => {
        setHoveredSpotIndex(null);
    };

    const handleImageClick = () => {
        setSelectedSpotIndices(new Set());
    };

    const handleTypeChange = (e) => {
        selectedSpotIndices.forEach(index => {
            const currentSpot = currentSpotData[index];
            const permitLetter = permits.find(permit => permit.description === e.target.value).permitType;
            currentSpot.type = permitLetter;

            const newBuild = {
                spotID: currentSpot.spotID,
                permitType: currentSpot.type,
                cameras: {
                    [cameraData.name.toLowerCase()]: currentSpot.boundingBox
                }
            };

            setChangedTypes(prevChangedTypes => [...prevChangedTypes, newBuild]);
        });

        setCurrentSpotData([...currentSpotData]);
    };

    // Runs drilled prop function to save data to database
    const saveToDatabase = async () => {
        setloaded(false)
        const uniqueChangedTypes = [];
        const spotIDSet = new Set();

        for (let i = changedTypes.length - 1; i >= 0; i--) {
            if (!spotIDSet.has(changedTypes[i].spotID)) {
                uniqueChangedTypes.unshift(changedTypes[i]);
                spotIDSet.add(changedTypes[i].spotID);
            }
        }

        setChangedTypes(uniqueChangedTypes);

        console.log("Changes to save to database:", uniqueChangedTypes);
        closeEditor("save", uniqueChangedTypes);
    };

    const getSelectedSpotType = () => {
        // Get the type of the first selected spot (or return empty string if no spots are selected)
        const firstSelectedIndex = Array.from(selectedSpotIndices)[0];
        const typeLetter = currentSpotData[firstSelectedIndex]?.type;
        if (typeLetter) {
            return permits.find(permit => permit.permitType === typeLetter)?.description || "";
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    let availableSpotTypes = [];
    const orgPermits = orgData.permitTypes;
    for (let i in orgPermits) {
        availableSpotTypes.push(orgPermits[i].description)
    }

    return (
        <div className="flex flex-col gap-4 justify-center items-center relative w-3/4" ref={editorRef}>
            {loading ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4 py-1 rounded-lg shadow-md">Loading...</div> :
                <div className="fcc w-full">
                    <div className="fcc relative w-full">
                        <img
                            src={image}
                            onLoad={handleImageLoad}
                            onLoadCapture={() => setloaded(true)}
                            alt="Parking Spots"
                            className="w-full rounded-xl pointer-events-none"
                            onClick={handleImageClick}
                        />
                        {errorOut ? <div className="absolute bottom-4 left-4 bg-red-200 text-spotGray p-4 font-bold rounded-lg">Error loading spot data</div> :
                            <svg
                                className="absolute top-0 left-0 w-full h-full pointer-events-none"
                                viewBox={`0 0 ${resolution.width} ${resolution.height}`}
                            >
                                {currentSpotData.map((spot, index) => {
                                    const color = permits.find((permit) => permit.permitType === spot.type)?.color;
                                    // Convert percentage-based coordinates to pixel coordinates
                                    const points = spot.boundingBox
                                        .map((point) => {
                                            const [xPercent, yPercent] = JSON.parse(point);
                                            const x = xPercent * resolution.width;
                                            const y = yPercent * resolution.height;
                                            return `${x},${y}`;
                                        })
                                        .join(" ");

                                    return (
                                        <polygon
                                            key={index}
                                            points={points}
                                            aria-label="Parking Spot"
                                            onClick={(e) => handleSpotClick(index, e)}
                                            onMouseEnter={() => handleSpotMouseEnter(index)}
                                            onMouseLeave={handleSpotMouseLeave}
                                            className="cursor-pointer pointer-events-auto"
                                            fill={selectedSpotIndices.has(index) ? color + "90" : hoveredSpotIndex === index ? color + "50" : "transparent"}
                                            stroke={selectedSpotIndices.has(index) ? color : color}
                                            strokeWidth={selectedSpotIndices.has(index) ? 5 : 3}
                                        />
                                    );
                                })}
                            </svg>
                        }
                        {loaded &&
                            <div
                                onClick={saveToDatabase}
                                aria-label="Save Changes"
                                className="absolute bottom-6 right-6 px-4 py-2 text-spotGray font-bold yellow-gradient cursor-pointer rounded"
                            >
                                Save
                            </div>
                        }
                    </div>
                    {
                        loaded &&
                        <p className="w-full italic text-xs drop-shadow-lg text-white pt-1">*Image is not live</p>
                    }
                </div >
            }
            {
                selectedSpotIndices.size > 0 && (
                    <div className="bg-white/70 p-2 backdrop-blur-md flex flex-col justify-start items-center h-fit gap-2 rounded-lg top-6 left-6">
                        <div className="frc gap-2 text-lg">
                            <div className="font-bold">Spot ID:</div>
                            <div>{currentSpotData[Array.from(selectedSpotIndices)[0]].spotID}</div>
                        </div>
                        <label className="font-medium text-gray-700">Spot Type:</label>
                        <select
                            value={getSelectedSpotType()}
                            aria-label="Spot Type"
                            onChange={handleTypeChange}
                            className="p-2 border border-gray-300 rounded"
                        >
                            {availableSpotTypes.map((type, index) => (
                                <option key={index} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                        <h3 className=" italic text-xs text-spotGray">Tip: Hold ctrl/cmd to select multiple spots</h3>
                    </div>
                )
            }
        </div >
    );
};