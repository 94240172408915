import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchData } from "../utility/fetcher";
import { userPool } from '../utility/awsMethods';
import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { GoogleLogin } from '@react-oauth/google';
import { Toaster } from "sonner";
import "./login.css";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [key, setKey] = useState(0);
    const jsCookie = Cookies.get("token");

    const finalizeLogin = async (org) => {
        try {
            localStorage.setItem("organization", org);

            for (let i in Object.keys(userInfo)) { localStorage.setItem(Object.keys(userInfo)[i], Object.values(userInfo)[i]) }

            Cookies.set("token", userInfo.token, { expires: 7 });
            setLoading(false);
            window.location.href = "/";
        } catch (error) {
            setLoading(false);
            console.error("Error validating user:", error);
        };
    };

    const [userInfo, setUserInfo] = useState({});
    const [allowedOrgs, setAllowedOrgs] = useState([]);

    const checkAllowedOrgs = async (email) => {
        // Check for user permissions
        const isAllowed = await fetchData(`/general/login?email=${email}&type=standard`);
        const org = isAllowed[0]

        if (isAllowed.length === 0) {
            setLoading(false);
            setErrorMessage('Please contact your organization administrator for access.');
            return;
        };

        // If user is allowed at multiple locations, show list
        if (isAllowed.length > 1) {
            setAllowedOrgs(isAllowed);
            setLoading(false);
            return;
        } else { finalizeLogin(org.orgName) };

        setLoading(false);
    };

    const handleSubmit = async (event, demo = false) => {
        event.preventDefault();

        const tempEmail = demo ? 'demo@spotparking.app' : email.trim().toLowerCase();
        const tempPassword = demo ? 'spotparking' : password;

        if (!validateEmail(tempEmail)) {
            setLoading(false);
            setErrorMessage("Please enter a valid email.")
            return;
        } else {
            setErrorMessage("");
        }

        if (tempPassword === "") {
            setLoading(false);
            setErrorMessage("Please enter a password.");
            return;
        };

        setErrorMessage("");
        setLoading(true);


        var authenticationData = {
            Username: tempEmail,
            Password: tempPassword,
        };

        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        var userData = {
            Username: tempEmail,
            Pool: userPool,
        };

        console.log(authenticationData)
        console.log(userData)

        var tempUserInfo = { email: tempEmail }

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async function (result) {
                // Store token
                var accessToken = result.getAccessToken().getJwtToken();
                tempUserInfo.token = accessToken;
                AWS.config.region = 'us-east-1';

                // Store user attributes
                cognitoUser.getUserAttributes(function (err, result) {
                    if (err) {
                        console.log(err.message || JSON.stringify(err));
                        return;
                    };
                    if (result) {
                        for (let i in result) {
                            tempUserInfo[result[i].getName()] = result[i].getValue();
                        };
                    };
                });

                setUserInfo(tempUserInfo);
                checkAllowedOrgs(tempEmail);
            },

            onFailure: function (err) {
                setLoading(false);
                setErrorMessage(err.message || JSON.stringify(err));
                return;
            },
        });
    };

    AWS.config.update({
        region: 'us-east-1', // Replace with your AWS region
    });

    // Google Sign-In handler
    const handleGoogleSignIn = async (credentialResponse) => {
        setLoading(true);
        try {
            const googleToken = credentialResponse.credential; // Google token
            const identityPoolId = "us-east-1:505c99c4-e14b-407c-9685-38718acca036"; // Your Identity Pool ID

            // Construct the Google token request to Cognito Identity Pool
            const logins = { 'accounts.google.com': googleToken };

            const cognitoIdentity = new AWS.CognitoIdentity();
            const params = {
                IdentityPoolId: identityPoolId, // Replace with your Identity Pool ID
                Logins: logins,  // Provide the Google token for authentication
            };

            cognitoIdentity.getId(params, (err, data) => {
                if (err) {
                    setLoading(false);
                    console.error("Error getting identity ID", err);
                    setErrorMessage("Google Sign-In failed");
                } else {
                    // To assume a role and get AWS credentials (optional, based on your app):
                    const identityId = data.IdentityId;
                    const credentialsParams = {
                        IdentityId: identityId,
                        Logins: logins,
                    };

                    cognitoIdentity.getCredentialsForIdentity(credentialsParams, async (error, credentialsData) => {
                        if (error) {
                            setLoading(false);
                            console.error("Error getting credentials", error);
                        } else {
                            const { AccessKeyId, SecretKey, SessionToken } = credentialsData.Credentials;
                            let email = "";

                            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityId: identityId,
                                Logins: logins,
                            });

                            AWS.config.credentials.get(async (err) => {
                                if (err) {
                                    setLoading(false);
                                    console.error("Error getting AWS credentials", err);
                                } else {
                                    const idToken = googleToken; // Use the Google token as idToken
                                    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
                                    email = decodedToken.email;

                                    setUserInfo({
                                        ...userInfo,
                                        email: email,
                                        token: SessionToken,
                                        accessKey: AccessKeyId,
                                        secretKey: SecretKey,
                                    });

                                    checkAllowedOrgs(email);
                                };
                            });
                        };
                    });
                };
            });
        } catch (error) {
            setLoading(false);
            console.error("Google Sign-In failed:", error);
            setErrorMessage("Failed to sign in with Google");
        }
    };

    const demoSubmit = async () => {
        setLoading((prev) => true);
        setErrorMessage((prev) => "");
        setUserInfo({
            email: "demo@spotparking.app",
            password: "spotparking",
            organization: "Demo",
        })
        setEmail((prev) => "demo@spotparking.app");
        setPassword((prev) => "spotparking");

        setTimeout(() => {
            handleSubmit(new Event("submit"), true); // Pass event + demo flag
        }, 1000);
    };

    const [loading, setLoading] = useState(false);
    const [isSSO, setIsSSO] = useState(true);

    const passwordInput = useRef(null);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };


    const handleSSOLogin = async () => {
        if (!validateEmail(email)) return
        setErrorMessage('');

        // Get the SSO URL
        const response = await fetchData(`/general/login?email=${email}&type=sso`);
        if (response && response.SSOUrl) {
            setIsSSO(true)
            const ssoUrl = response.SSOUrl;

            // TODO Build the callback url

            // Redirect to the SSO URL
            window.open(ssoUrl, "_blank");
        } else {
            setIsSSO(false);
            passwordInput.current?.focus();
        };
        setLoading(false);
    };


    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
        return <Navigate to="/" />;
    } else {
        console.log("Authenticated:", false);
        Cookies.remove("token");

        const allowedPaths = ["/login", "/contact-us", "/reset-password", "/signup"];
        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
            sessionStorage.clear();
            localStorage.clear();
        };
    };

    return (
        <div className="bg-gradient-to-t from-spotYellow to-gray-700 h-screen overflow-hidden relative justify-center flex items-center font-rubik">

            <div className={`flex flex-col justify-center items-center w-[400px] gap-4 ${loading ? 'opacity-50 select-none touch-none' : 'opacity-100'} transition-all duration-200`}>
                <div className="w-fit h-fit bg-white rounded-3xl p-8 z-50 text-spotGray justify-center items-center shadow-lg">
                    {allowedOrgs.length === 0 ? (
                        <>
                            <h1 className="text-3xl lg:text-5xl font-bold self-center mb-4">Login</h1>
                            <p className="text-lg lg:text-xl text-center mb-6">using credentials provided by <br />the Spot Parking team.</p>
                            <div className="flex flex-col justify-center items-center">
                                <form aria-label="Login Form" className="w-full flex flex-col justify-center items-center" onSubmit={(e) => e.preventDefault()}>
                                    <label className={`w-full`}>
                                        <div className="font-bold text-xl select-none">
                                            Email
                                        </div>
                                        <input
                                            type="text"
                                            alt="Email"
                                            value={email}
                                            autoComplete="email"
                                            tabIndex={1}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") { isSSO ? handleSSOLogin() : passwordInput.current?.focus() }
                                            }}
                                            className="w-full h-[40px] border border-gray-400 outline-spotGray rounded-lg pl-2 text-spotGray bg-white mb-4"
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                                setErrorMessage("")
                                            }}
                                        />
                                    </label>
                                    {!isSSO && (
                                        <label className={`w-full`}>
                                            <div className="font-bold text-xl select-none">
                                                Password
                                            </div>
                                            <input
                                                type="password"
                                                alt="Password"
                                                tabIndex={!isSSO ? 2 : null}
                                                ref={passwordInput}
                                                value={password}
                                                onKeyUp={(e) => { if (e.key === "Enter") handleSubmit(new Event("submit")); }}
                                                autoComplete="current-password"
                                                className={`w-full h-[40px] border border-gray-400 outline-spotGray rounded-lg pl-2 text-spotGray bg-white mb-4`}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                    setErrorMessage("")
                                                }}
                                            />
                                        </label>
                                    )}
                                    {errorMessage !== "" &&
                                        <p className="text-red-500 fond-bold mb-2 w-3/4 text-center text-wrap">
                                            {errorMessage}
                                        </p>
                                    }
                                    <div
                                        tabIndex={!isSSO ? 2 : 3}
                                        onKeyUp={(e) => { if (e.key === "Enter") { isSSO ? handleSSOLogin() : handleSubmit(new Event("submit")); } }}
                                        className={`w-fit px-8 h-12 mb-5 fcc cursor-pointer  rounded-2xl outline-spotGray hover:outline-spotYellow font-bold text-2xl transition-opacity duration-200 select-none hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg ${validateEmail(email) ? "opacity-100 bg-gradient-to-r from-spotYellow to-yellow-200" : "opacity-50 pointer-events-none bg-gradient-to-r from-gray-200 to-gray-300"}`}
                                        onClick={() => {
                                            if (isSSO) {
                                                handleSSOLogin();
                                            } else {
                                                handleSubmit(new Event("submit"));
                                            }
                                        }}
                                        aria-label="Login Button"
                                    >
                                        {isSSO ? "Next" : "Login"}
                                    </div>
                                </form>
                                <div className="text-lg text-spotGray flex flex-col gap-2 text-center select-none">
                                    <p>Don't have an account? <span><a className="ml-2 underline" href="/contact-us"> Contact Us</a></span></p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col gap-2 w-full">
                            <h1 className="text-2xl font-bold self-center mb-4">Select an Organization</h1>
                            <div className="flex flex-col gap-4 w-full">
                                {allowedOrgs.map((org, index) => (
                                    <div key={index} className="w-full h-[60px] p-0 bg-[#f5f5f5] rounded-[10px] flex flex-row justify-center items-center relative cursor-pointer hover:bg-gray-200 active:bg-gray-300 transition select-none" onClick={() => finalizeLogin(org.orgName)}>
                                        <img alt={`${org.orgName}-logo`} src={org.logo ? org.logo.light : ''} className="h-full py-1 object-contain aspect-video" />
                                    </div>
                                ))}
                            </div>
                            <div onClick={() => {
                                setAllowedOrgs([]);
                                setLoading(false);
                                setKey((prevKey) => prevKey + 1);
                            }} className="text-lg text-spotGray flex flex-col -mb-5 text-center select-none hover:cursor-pointer">
                                <p className="underline">Go back</p>
                            </div>
                        </div>
                    )}
                </div>
                {allowedOrgs.length === 0 && (
                    <div className="flex flex-row justify-between rounded-3xl h-12 lg:h-16 w-auto px-3 bg-white z-50 shadow-lg select-none">
                        <div className="flex justify-center items-center bg-spotGray cursor-pointer text-white rounded-full lg:rounded-2xl w-30 mr-2 h-9 lg:h-12 px-2 self-center font-bold text-sm lg:text-xl transition-all duration-200 hover:bg-spotYellow hover:text-spotGray hover:shadow-lg" onClick={demoSubmit}>
                            Click Here
                        </div>
                        <div className="flex flex-row font-bold text-sm lg:text-lg text-center self-center">
                            for the demo<span className=" drop-shadow-lg ml-2">👀</span>
                        </div>
                    </div>
                )}
            </div>

            {/* Google Sign In Button */}
            <div key={key} className="invisible absolute top-0 left-0 select-none touch-none">
                <GoogleLogin
                    shape="circle"
                    useOneTap={true}
                    onSuccess={handleGoogleSignIn}
                    onError={() => { console.log('Login Failed') }}
                />
            </div>

            {/* Floating boxes */}
            <div className="absolute w-screen h-screen z-10 overflow-hidden">
                <div className="bg-gray-200/20 hover:scale-105 rounded-3xl shadow-sm transition-all animate-floatMultiDirection1 absolute w-[70%] h-[50%] top-[70%] left-[70%]" ></div>
                <div className="bg-gray-200/20 hover:scale-105 rounded-3xl shadow-sm transition-all animate-floatMultiDirection2 absolute w-[40%] h-[50%] top-[70%] -left-[10%] rotate-45" ></div>
                <div className="bg-gray-200/20 hover:scale-105 rounded-3xl shadow-sm transition-all animate-floatMultiDirection3 absolute w-[50%] h-[45%] -top-[15%] left-[35%]" ></div>
            </div>
            <Toaster richColors />
        </div>
    );
}

export default Login;