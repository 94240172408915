import React, { useState, useEffect } from "react";
import { PieChart } from 'react-minimal-pie-chart';
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { fetchAllData } from "../utility/fetcher";
import Loader from "../utility/Loader";

const Dashboard = ({ topLoading, setTopLoading }) => {
    const [orgData, setOrgData] = useState({});
    const [loading, setLoading] = useState(true);
    const [spotData, setSpotData] = useState([]);
    const orgName = localStorage.getItem("organization");
    let orgDataResponse = localStorage.getItem("orgData");

    const storeData = async () => {
        try {
            setTopLoading(true);
            const orgResponse = await fetchAllData({ "orgData": `/public/getOrgByName?orgName=${orgName}` });
            orgDataResponse = orgResponse.orgData;
            setOrgData(orgDataResponse);
            localStorage.setItem("orgData", JSON.stringify(orgDataResponse));
            const orgID = orgDataResponse.orgID;
            const urls = {
                "violationData": `/${orgID}/spots`,
                // "violationHistory": `/${orgID}/history`,
                "lineData": `/${orgID}/lines`,
            };

            const finalData = await fetchAllData(urls);

            sessionStorage.setItem("violationData", JSON.stringify(finalData.violationData));
            setSpotData(finalData.violationData);
            // sessionStorage.setItem("violationHistory", JSON.stringify(finalData.violationHistory));
            sessionStorage.setItem("lineData", JSON.stringify(finalData.lineData));

            const lots = orgDataResponse.lots;
            let cameraArray = [];
            for (let i in lots) {
                const lot = lots[i];
                const lotCameras = lot.cameras;
                for (let j in lotCameras) {
                    cameraArray.push(lotCameras[j]);
                };
            };

            sessionStorage.setItem("cameraArray", JSON.stringify(cameraArray));
            setLoading(false);
            setTopLoading(false);
        } catch (error) {
            console.error("Error storing data:", error);
        };
    };

    useEffect(() => {
        let intervalId = null;

        const getNewData = async () => {
            const newData = await fetchAllData({ "violationData": `/${orgDataResponse.orgID}/spots` });
            localStorage.setItem("violationData", JSON.stringify(newData.violationData));
            setSpotData(newData.violationData);
        };

        // Every ten seconds, update the map data. This is a very expensive operation. Not in regard to the code, but in regard to the cost of the API calls.
        const startPeriodicDataGetter = () => {
            intervalId = setInterval(async () => {
                await getNewData();
            }, 10000); //! This has direct influence on our costs. Think deeply if you are wanting to change this.
        };

        const stopPeriodicDataGetter = () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
                intervalId = null;
            };
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startPeriodicDataGetter();
            } else {
                stopPeriodicDataGetter();
            };
        };

        // Set up event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Initial start when the component is first mounted
        if (document.visibilityState === 'visible') {
            startPeriodicDataGetter();
        };

        // Cleanup function
        return () => {
            stopPeriodicDataGetter();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        // eslint-disable-next-line
    }, []);

    // find number of lots
    function findAllLots(lotData) {
        for (let i in lotData) {
            lotNumbers.push(lotData[i].lotNum);
        };

        const uniqueLotNumbers = [...new Set(lotNumbers)].sort();
        return uniqueLotNumbers;
    };

    // For each lot, find total number of violations
    function vehiclesPerLot(violationData) {
        let lotsObj = {};

        for (let i in violationData) {
            const lot = violationData[i].lot;

            if (lotsObj[lot] === undefined) {
                lotsObj[lot] = {
                    violations: 0,
                    ticketsPending: 0,
                    ticketsWritten: 0,
                    totalCars: 0,
                    occupancy: 0
                };
            };

            lotsObj[lot].occupancy++;
            if (violationData[i].licensePlate !== "") {
                lotsObj[lot].totalCars++;
            };

            if (violationData[i].ticketWritten === false && violationData[i].violationType !== "") {
                lotsObj[lot].ticketsPending++;
            } else if (violationData[i].ticketWritten === true) {
                lotsObj[lot].ticketsWritten++;
            };

            lotsObj[lot].violations = lotsObj[lot].ticketsPending + lotsObj[lot].ticketsWritten;
        };

        return lotsObj;
    };

    // Calculate total occupancy
    function getTotalOccupancy(perLotData) {
        let totalOccupancy = 0;
        for (let lot in perLotData) {
            totalOccupancy += perLotData[lot].occupancy;
        };
        return totalOccupancy;
    };

    // Calculate average occupancy
    function getAverageOccupancy(perLotData) {
        let totalOccupancy = 0;
        let totalLots = 0;

        for (let lot in perLotData) {
            totalOccupancy += (perLotData[lot].totalCars / perLotData[lot].occupancy);
            totalLots++;
        };

        var avgOccupancy = Math.round((totalOccupancy / totalLots) * 100);
        avgOccupancy = isNaN(avgOccupancy) ? 0 : avgOccupancy;
        return avgOccupancy;
    };

    const barChart = (percentage, type) => {
        var roundedPercentage = Math.round((percentage) * 100);
        roundedPercentage = isNaN(roundedPercentage) ? 0 : roundedPercentage;
        roundedPercentage = type === "average" ? averageOccupancy : roundedPercentage;

        var color = "";
        if (roundedPercentage <= 25) {
            color = "bg-[#C6F1CA]"
        } else if (roundedPercentage <= 80) {
            color = "yellow-gradient"
        } else {
            color = "bg-[#FDC1C0]"
        };

        function marginCalculator(roundedPercentage) {
            const value = 150 - (150 - roundedPercentage * 1.5);
            if (type === "average") {
                if (roundedPercentage === 100) {
                    return value - 92 + "px";
                } else if (roundedPercentage < 50) {
                    return value + 4 + "px";
                } else {
                    return value - 82 + "px";
                };
            } else {
                if (roundedPercentage === 100) {
                    return value - 55 + "px";
                } else if (roundedPercentage < 35) {
                    return value + 6 + "px";
                } else {
                    return value - 45 + "px";
                };
            };
        };

        const width = roundedPercentage + "%";
        const margin = marginCalculator(roundedPercentage);

        return (
            <div key={"bar"} className="flex flex-row justify-left items-center w-36 h-7 bg-white shadow-md rounded-md my-2 cursor-pointer hover:scale-105 transition-all duration-100">
                <div className={`h-7 rounded-md ${color} p-0 m-0 shadow-md`} style={{ width: width }}></div>
                <div className="absolute p-0 m-0 font-semibold" style={{ marginLeft: margin }}>{type === "average" ? `Avg: ${roundedPercentage}` : roundedPercentage}%</div>
            </div>
        );
    };

    const OccupancyPie = () => {
        return (
            <div className="flex flex-col scale-75 lg:scale-100 items-center h-96 w-96 shadow-lg border bg-white rounded-3xl">
                <h1 className="text-4xl font-bold text-spotGray mb-4 mt-4">Campus Occupancy</h1>
                <div className="w-72">
                    <PieChart
                        data={[{ value: piePercentage, color: "#FFE374" }]}
                        totalValue={100}
                        lineWidth={25}
                        label={({ dataEntry }) => dataEntry.value + "%"}
                        labelStyle={{
                            fontSize: '25px',
                            fontFamily: 'sans-serif',
                            fill: '#323232',
                        }}
                        labelPosition={0}
                        startAngle={-90}
                        background="#F1F1F1"
                        rounded="true"
                    />
                </div>
            </div>
        );
    };

    const Table = () => {
        return (
            <div className="relative flex flex-col items-center w-full h-fit p-0 bg-white rounded-3xl shadow-lg border">
                <h1 className="text-xl lg:text-3xl font-bold text-spotGray mb-4 mt-4">Parking Lot Occupancy</h1>
                <div className="overflow-x-auto w-full">
                    <table className="table-auto mb-5 w-full">
                        <thead className="shadow-lg pb-6">
                            <tr>
                                <th className="pl-4 pb-2">Lot Number</th>
                                <th className="pb-2">Occupancy</th>
                                <th className="font-light px-2 pb-2">Total Vehicles</th>
                                <th className="font-light px-2 pb-2">Tickets Pending</th>
                                <th className="font-light px-2 pb-2">Tickets Written</th>
                                <th className="px-2 pb-2">Total Violations</th>
                                <th className="pr-4 pb-2">Auto</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {Object.keys(perLotData).map((lot, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="font-bold">{lot}</td>
                                        <td className="flex justify-center">{barChart(perLotData[lot].totalCars / perLotData[lot].occupancy)}</td>
                                        <td>{perLotData[lot].totalCars}</td>
                                        <td>{perLotData[lot].ticketsPending}</td>
                                        <td>{perLotData[lot].ticketsWritten}</td>
                                        <td>{perLotData[lot].violations}</td>
                                        <td className="flex justify-center align-middle mb-4 w-10">{autoStatuses[lot] ? <FaCheckCircle color="#59974D" size={20} /> : <FaCircleXmark color="#D7504D" size={20} />}</td>
                                    </tr>
                                );
                            })}
                            <tr className="border-t-2">
                                <td className="font-bold">Totals</td>
                                <td className="flex justify-center">{barChart(lotData[0], "average")}</td>
                                <td>{Object.values(perLotData).reduce((total, lot) => total + lot.totalCars, 0)}</td>
                                <td>{Object.values(perLotData).reduce((total, lot) => total + lot.ticketsPending, 0)}</td>
                                <td>{Object.values(perLotData).reduce((total, lot) => total + lot.ticketsWritten, 0)}</td>
                                <td>{Object.values(perLotData).reduce((total, lot) => total + lot.violations, 0)}</td>
                                <td>{autoFraction}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="absolute top-0 right-0 fcc gap-1 border-l border-b rounded-bl-xl">
                    <p className="font-bold pl-2 pt-1 pr-3">Total Lots</p>
                    <p className="">{Object.keys(perLotData).length}</p>
                </div>
            </div>
        );
    };

    var autoStatuses = {};
    function getAutoStatuses(lots) {
        for (let i in lots) {
            autoStatuses[lots[i]["lotNum"]] = lots[i]["autoStatus"];
        };
        return autoStatuses;
    };

    // Start by fetching data
    useEffect(() => {
        storeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <div className="flex flex-row items-center justify-center h-screen w-screen">
                <Loader />
            </div>
        );
    };

    var lotNumbers = [];
    const violationData = spotData;
    const currentOrgData = orgData;

    if (currentOrgData.error) {
        return (
            <div className="flex flex-row justify-center h-screen w-screen">
                <h1 className="text-3xl font-bold text-spotGray mt-20">No data available.</h1>
            </div>
        );
    };

    const lotData = currentOrgData ? currentOrgData.lots : null;
    const activeLots = findAllLots(lotData);
    const perLotData = vehiclesPerLot(violationData);
    const averageOccupancy = getAverageOccupancy(perLotData);
    const totalOccupancy = getTotalOccupancy(perLotData);
    const totalVehicles = Object.values(perLotData).reduce((total, lot) => total + lot.totalCars, 0);
    var piePercentage = totalVehicles === 0 || totalOccupancy === 0 ? 0 : Math.round((totalVehicles / totalOccupancy) * 100);
    const countAutoStatuses = Object.values(autoStatuses).filter(status => status).length;
    const autoFraction = `${countAutoStatuses}/${activeLots.length}`;
    getAutoStatuses(lotData);

    return (
        <div className="flex flex-row flex-wrap justify-center gap-8 w-full mt-10 pl-24 py-10 pr-4 font-rubik">
            {loading ? (
                <div className="flex flex-row items-center justify-center h-screen w-screen">
                    <Loader />
                </div>
            ) : (
                <>
                    <div className="flex flex-col justify-center items-center gap-8">
                        <OccupancyPie />
                        <Table />
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;